import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Joint from './Joint'



function ThreeScene(){
  const mount = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // Sizes
    const sizes:{width:number, height:number} = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    // Window resizing
    window.addEventListener('resize', () => {
        //Update Sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
        
        //Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
    
        //Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        
        renderer.render(scene, camera)    
    })


    /**
     * Scene
     */
    const scene:THREE.Scene = new THREE.Scene();
    
    //Camera
    const camera:THREE.PerspectiveCamera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
    camera.position.set(5,5,5)
    scene.add(camera)



    /**
     * Models
     */
    const gltfLoader = new GLTFLoader()
    gltfLoader.load(
      // '../models/MH5F_stl/scene.gltf',
      '/models/MH5F_stl/mh5f.gltf',
      ( gltf )=>{
        console.log('success')
        console.log(gltf)
        scene.add(gltf.scene)
      },
      ( progress )=>{
  
        console.log( ( progress.loaded / progress.total * 100 ) + '% loaded' );
    
      },
      ( error ) => {
        console.log( error )
      }
    )
    


    //Object
    // Joint({scene})

    /**
     * Floor
     */
    const floor = new THREE.Mesh(
      new THREE.PlaneGeometry(20, 20, 5, 5),
      new THREE.MeshBasicMaterial({
          color: '#444444',
          wireframe: true
      })
    )
    floor.rotation.x = - Math.PI * 0.5
    scene.add(floor)

    /**
     * Lights
     */

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8)
    scene.add(ambientLight, directionalLight)
    
    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(sizes.width, sizes.height)
    
    mount.current?.appendChild( renderer.domElement )
    
    // Controls
    const controls = new OrbitControls(camera, mount.current?.appendChild(renderer.domElement))
    controls.enableDamping = true

    renderer.render(scene, camera)


    /**
     * Animate
     */
    const clock = new THREE.Clock()
    const tick = () =>
    {
        // Update controls
        controls.update()

        // Render
        renderer.render(scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }
    tick()

    return (
      () => { 
          mount.current?.removeChild( renderer.domElement )
          console.log('removed child')
      }
    )
  }, [])


  return(
    <div className='three-scene' ref={mount} />
  )
}

export default ThreeScene;
