import React from "react";
import CorporigidoLogo from "./CorporigidoLogo"
import './NavMenu.css'

function NavMenu(){
    return(
        <nav className="NavMenu">
            <ul>
                <li><CorporigidoLogo /></li> 
            </ul>
        </nav>
    )
}

export default NavMenu