import React from 'react'

function CorporigidoLogo(){
    return (
        <span className='CorporigidoLogo'>
            <strong>CORPORIGIDO.<span style={{color: 'rgb(204, 16, 16)'}}>X</span><span style={{color: 'rgb(0, 128, 0)'}}>Y</span><span style={{color: 'rgb(17, 17, 218)'}}>Z</span></strong>
        </span>
    )
}

export default CorporigidoLogo