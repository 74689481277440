import React from 'react';
import ThreeScene from './components/ThreeScene'
import NavMenu from './components/layout/NavMenu'
import './App.css';

function App() {
  return (
    <div className="App">
      <NavMenu />
      <div className="viewer">
        <ThreeScene />
      </div>
      <footer>
        <p>&copy; Copyright 2021</p>
      </footer>
    </div>
  );
}

export default App;
